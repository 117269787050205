import React, { useEffect, useState } from "react";
import { Button, Col, DatePicker, Pagination, Row } from "antd";
import moment from "moment";
import PropTypes from "prop-types";

import useHttp from "../../hooks/use-http";
import ModalFormCreator from "./ModalFormCreator";
import CRUDTable from "./CRUD-Table";
import CustomSearchBar from "./Custom-search";
import { apiGenerator } from "../../util/functions";
import dayjs from "dayjs";

const { RangePicker } = DatePicker;
const CRUDComponent = React.memo((propUp) => {
  const {
    GET,
    CREATE,
    UPDATE,
    DELETE,
    isSearch = false,
    searchKey = "searchQuery",
    datefilter = false,
    AddOnComponent = null,
    externalRefreshState = false,
    selectionOff = false,
    FILTERSORTKEY = {},
    maxPageCount = 90000,
    rowSelectionObj,
    props,
  } = propUp;
  const [data, setData] = useState([]);
  const [Allfilter, setAllFilter] = useState(null);
  const [search, setSearch] = useState("");
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 20,
    total: 0,
  });
  const [createOpen, setCreateOpen] = useState(false);
  const [updateOpen, setUpdateOpen] = useState(null);
  const [formData, setFormData] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const [dates, setDates] = useState({
    startDate: null,
    endDate: null,
  });

  const API = useHttp();

  useEffect(() => {
    let QuaryParams = {
      limit: pagination.pageSize,
      page: pagination.current,
    };
    if (GET?.extraQuery) {
      QuaryParams = { ...QuaryParams, ...GET?.extraQuery };
    }
    if (
      dates &&
      dates?.startDate &&
      dates?.endDate &&
      dates?.startDate !== null &&
      dates?.endDate !== null
    ) {
      QuaryParams.startDate = dates?.startDate;
      QuaryParams.endDate = dates?.endDate;
    }
    if (Allfilter?.sort) {
      const sorter = { ...Allfilter?.sort };
      sorter.sortBy = FILTERSORTKEY?.[sorter?.sortBy] || sorter?.sortBy;
      QuaryParams = { ...QuaryParams, ...sorter };
    }
    if (Allfilter?.filter) {
      Allfilter?.filter?.map((el) => {
        QuaryParams = {
          ...QuaryParams,
          [`autogenerate-mul-array-${FILTERSORTKEY?.[el[0]] || el[0]}`]: el[1],
        };
      });
    }
    if (search?.trim().length) {
      QuaryParams = { ...QuaryParams, [searchKey]: search };
    }
    GET &&
      GET?.API &&
      API.sendRequest(
        apiGenerator(GET?.API, GET?.extraReplaceObject),
        (res) => {
          setPagination((prevPagination) => {
            return {
              ...prevPagination,
              total: res?.data?.count
                ? res?.data?.count
                : prevPagination.current > 1
                  ? maxPageCount
                  : 0,
            };
          });
          let ResultData = res?.data?.rows || res?.data;
          if (GET?.DataModifier) {
            ResultData = GET?.DataModifier(ResultData, API, setRefresh);
          }
          ResultData = ResultData?.map((el) => {
            const SingleRow = {
              ...el,
              key: el.id,
            };
            if (
              DELETE &&
              DELETE?.API &&
              GET?.column?.findIndex(
                (el) => el?.dataIndex === "deleteTableRow"
              ) !== -1
            ) {
              SingleRow.deleteTableRow = {
                id: el.id,
                key: el.id,
                message: DELETE?.confirm_message,
                onClick: (key) => {
                  const DeleteAPITableRow = { ...DELETE?.API };
                  DeleteAPITableRow.endpoint = `${DeleteAPITableRow.endpoint}${key}`;
                  API.sendRequest(
                    DeleteAPITableRow,
                    (res) => {
                      setData((prev) =>
                        prev.filter((item) => item.key !== key)
                      );
                    },
                    "",
                    DELETE?.message
                  );
                },
              };
            }
            if (
              UPDATE &&
              UPDATE?.API &&
              UPDATE?.modalFields &&
              GET?.column?.findIndex(
                (el) => el?.dataIndex === "editTableRow"
              ) !== -1
            ) {
              SingleRow.editTableRow = {
                id: el.id,
                key: el.id,
                onClick: () => {
                  if (UPDATE?.updateSingleAPICall) {
                    UPDATE?.updateOnclick(el?.id, setUpdateOpen, setFormData, SingleRow)
                  } else {
                    setUpdateOpen({ ...SingleRow });
                    setFormData({ ...SingleRow });
                  }
                }

                // UPDATE?.updateOnclick ?
                //   UPDATE?.updateSingleAPICall(el?.id, setUpdateOpen, setFormData, SingleRow)
                //   : () => {
                //     setUpdateOpen({ ...SingleRow });
                //     setFormData({ ...SingleRow });
                //   }
                // () => {
                //   if (UPDATE?.updateSingleAPICall) {
                //     API.sendRequest(
                //       {
                //         type: "GET"
                //         , endpoint: UPDATE?.updateSingleAPICall?.endpoint + el?.id
                //       },
                //       (res) => {

                //         setUpdateOpen({ ...SingleRow, ...res?.data?.sharkSpecies, image });
                //         setFormData({ ...SingleRow, ...res?.data?.sharkSpecies });
                //       }
                //     )
                //   } else {
                //     setUpdateOpen({ ...SingleRow });
                //     setFormData({ ...SingleRow });
                //   }
                // },
              };
            }
            return { ...SingleRow };
          });

          setData(ResultData);
        },
        QuaryParams
      );
  }, [
    refresh,
    pagination.current,
    pagination.pageSize,
    Allfilter,
    search,
    dates,
    GET?.extraQuery,
  ]);

  useEffect(() => {
    setPagination((prev) => ({ ...prev, current: 1 }));
  }, [externalRefreshState]);
  const dateFilterFunction = (e) => {
    if (e) {
      setDates({
        startDate: dayjs(e[0])?.format("YYYY-MM-DD"),
        endDate: dayjs(e[1])?.format("YYYY-MM-DD"),
      });
    } else {
      setDates({
        startDate: null,
        endDate: null,
      });
    }
  };
  const onCreate = (value, clear) => {
    if (CREATE && CREATE?.API && CREATE?.modalFields) {
      let payload = payloadGenerator(
        value,
        CREATE?.modalFields,
        CREATE?.isFormData
      );

      if (CREATE?.payloadModifier) {
        payload = CREATE?.payloadModifier(payload, value);
      }

      if (CREATE?.onCustomUpdate) {
        CREATE?.onCustomAdd(API, payload, CREATE, () => {
          setRefresh((prev) => !prev);
          setCreateOpen(false);
          clear();
        });
        return;
      }

      API.sendRequest(
        CREATE?.API,
        () => {
          setRefresh((prev) => !prev);
          setCreateOpen(false);
          clear();
        },
        payload,
        CREATE?.message
      );
    }
  };
  const onUpdate = (value, clear) => {
    if (UPDATE && UPDATE?.API && UPDATE?.modalFields) {
      let payload = payloadGenerator(
        value,
        UPDATE?.modalFields,
        UPDATE?.isFormData
      );

      if (UPDATE?.payloadModifier) {
        payload = UPDATE?.payloadModifier(payload, formData, value);
      }

      if (UPDATE?.onCustomUpdate) {
        UPDATE?.onCustomUpdate(API, updateOpen?.id, payload, UPDATE, () => {
          setUpdateOpen(null);
          setFormData(null);
          setRefresh((prev) => !prev);
          clear();
        });
        return;
      }

      const UpdateAPIEnd = { ...UPDATE?.API };
      UpdateAPIEnd.endpoint = `${UpdateAPIEnd?.endpoint}${updateOpen?.id}`;
      API.sendRequest(
        UpdateAPIEnd,
        () => {
          setUpdateOpen(null);
          setFormData(null);
          setRefresh((prev) => !prev);
          clear();
        },
        payload,
        UPDATE?.message
      );
    }
  };

  return (
    <Row className="gap-4 mt-6">
      {CREATE && CREATE?.API && CREATE?.modalFields && (
        <ModalFormCreator
          loading={API.isLoading}
          open={createOpen}
          onCreate={onCreate}
          onCancel={() => {
            setCreateOpen(false);
          }}
          menuFields={CREATE?.modalFields}
          formData={{}}
          name={CREATE?.modaltitle || `Add `}
          SubmitName={"Submit"}
        />
      )}
      {UPDATE && UPDATE?.API && UPDATE?.modalFields && (
        <ModalFormCreator
          loading={API.isLoading}
          open={updateOpen !== null}
          onCreate={onUpdate}
          onCancel={() => {
            setUpdateOpen(null);
            setFormData(null);
          }}
          menuFields={UPDATE?.modalFields}
          formData={formData}
          name={UPDATE?.modaltitle || `Edit`}
          SubmitName={"Update "}
        />
      )}
      {CREATE && CREATE?.API ? (
        <Col span={24}>
          <Row gutter={[8, 8]}>
            <Col>
              <Button
                loading={API.isLoading}
                onClick={() => {
                  setCreateOpen(true);
                }}
              >
                {CREATE?.name ?? "Add"}
              </Button>
            </Col>

            {CREATE?.AddAfterAddButton}
          </Row>
        </Col>
      ) : (
        CREATE?.AddAfterAddButton && CREATE?.AddAfterAddButton
      )}
      {GET?.column?.length && (
        <>
          {isSearch && (
            <Col span={24} sm={24} md={20} lg={8} xl={5} xxl={4}>
              <CustomSearchBar
                setKeyword={(v) => {
                  setSearch(v);
                  setPagination((prev) => ({ ...prev, current: 1 }));
                }}
                isSearch={isSearch}
              />
            </Col>
          )}
          {datefilter && (
            <Col span={24} sm={24} md={20} lg={8} xl={5} xxl={4}>
              <RangePicker onChange={dateFilterFunction} />
            </Col>
          )}

          {AddOnComponent}
          <Col span={24}>
            <CRUDTable
              dataSource={data}
              isLoading={API.isLoading}
              columns={GET?.column}
              selectionOff={selectionOff}
              setChanges={(v) => {
                setAllFilter(v);
                setPagination((prev) => ({
                  ...prev,
                  current: 1,
                }));
              }}
              rowSelectionObj={rowSelectionObj}
              props={props}
            />
          </Col>
          <Col span={24} className="mb-4">
            <Pagination
              current={pagination?.current}
              pageSize={pagination?.pageSize}
              total={pagination?.total}
              showSizeChanger
              onChange={(page, pageSize) => {
                setPagination((prev) => ({ ...prev, pageSize, current: page }));
              }}
              showQuickJumper
              disabled={API.isLoading}
            />
          </Col>
        </>
      )}
    </Row>
  );
});
CRUDComponent.propTypes = {
  GET: PropTypes.shape({
    API: PropTypes.shape({
      type: PropTypes.string,
      endpoint: PropTypes.string,
    }),
    extraQuery: PropTypes.object,
    DataModifier: PropTypes.func,
    column: PropTypes.array,
  }),
  CREATE: PropTypes.shape({
    API: PropTypes.shape({
      type: PropTypes.string,
      endpoint: PropTypes.string,
    }),
    payloadModifier: PropTypes.func,
    modalFields: PropTypes.array,
    modaltitle: PropTypes.string,
    isFormData: PropTypes.bool,
    message: PropTypes.string,
    name: PropTypes.string,
  }),
  UPDATE: PropTypes.shape({
    API: PropTypes.shape({
      type: PropTypes.string,
      endpoint: PropTypes.string,
    }),
    payloadModifier: PropTypes.func,
    modalFields: PropTypes.array,
    modaltitle: PropTypes.string,
    isFormData: PropTypes.bool,
    message: PropTypes.string,
    updateSingleAPICall: PropTypes.bool,
    updateOnclick: PropTypes.shape({
      id: PropTypes.string,
      setUpdateOpen: PropTypes.any,
      setFormData: PropTypes.any,
      SingleRow: PropTypes.any,
    }),
  }),

  DELETE: PropTypes.shape({
    API: PropTypes.shape({
      type: PropTypes.string,
      endpoint: PropTypes.string,
    }),
    message: PropTypes.string,
  }),
  isSearch: PropTypes.bool,
  selectionOff: PropTypes.bool,
  FILTERSORTKEY: PropTypes.object,
  maxPageCount: PropTypes.number,
  rowSelectionObj: PropTypes.shape({
    type: PropTypes.oneOf(["checkbox", "radio"]),
    onChange: PropTypes.func,
  }),
};
export default CRUDComponent;

export const payloadGenerator = (value, fields, isFormData) => {
  let rawPayload = {};
  const formPayload = new FormData();

  if (isFormData) {
    fields?.forEach((ele) => {
      if (
        ele.type !== "file" &&
        ele.type !== "date" &&
        ele.type !== "multifield" &&
        ele.type !== "extraMultiSingle" &&
        ele.type !== "number" &&
        ele.type !== "fileWithPreview"
      ) {
        value[ele.id] && formPayload.append(ele.id, value[ele.id]);
      }

      if (
        (ele.type === "file" || ele.type === "fileWithPreview") &&
        value[ele?.id]
      ) {
        if (Array.isArray(value[ele?.id])) {
          if (Array.isArray(value[ele.id])) {
            for (let i = 0; i < value[ele.id]?.length; i++) {
              formPayload.append(ele.id, value[ele.id][i].originFileObj);
            }
          } else {
            value[ele.id] &&
              formPayload.append(
                ele.id,
                value[ele.id][0].originFileObj || value[ele.id]
              );
          }
        }
      }
      if (ele.type === "multifield" || ele.type === "extraMultiSingle") {
        if (ele?.handler) {
          value[ele.id] &&
            formPayload.append(ele.id, ele?.handler(value[ele.id]));
        } else {
          value[ele.id] &&
            formPayload.append(ele.id, JSON.stringify(value[ele.id]));
        }
      }

      if (ele.type === "number") {
        value[ele.id] && formPayload.append(ele.id, +value[ele.id]);
      }
      if (ele.type === "date") {
        const dateTimeValue = `${moment(value[ele.id].$d).format(
          "YYYY-MM-DD"
        )} ${moment(value[ele.id].$d, "HH:mm:ss").utc().format("HH:mm:ss")}`;

        value[ele.id] && formPayload.append(ele.id, dateTimeValue);
      }
    });
  } else {
    fields.forEach((ele) => {
      if (ele?.type === "date") {
        rawPayload = {
          ...rawPayload,
          [ele?.id]: moment(value[ele?.id]?.$d, "YYYY-MM-DD").format(
            "YYYY-MM-DD"
          ),
        };
      }
      if (ele?.type === "time") {
        rawPayload = {
          ...rawPayload,
          [ele?.id]: moment(value[ele?.id]?.$d, "HH:mm:ss").format("HH:mm:ss"),
        };
      }
    });
    rawPayload = { ...value, ...rawPayload };
  }

  return isFormData ? formPayload : rawPayload;
};
