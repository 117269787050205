import React, { useState, useRef, useEffect } from 'react';

/**
 * DraggableImage Component
 * Allows users to drag the image vertically to adjust the Y position for `object-position`.
 */
let startY = 0;
let imageStartY = 0;
let imgHeight = 0;
let imgRatio = 1;

const DraggableImage = ({
    imageUrl,
    alt = 'drag',
    objectFit = 'cover',
    defaultPosition = 'center 0%',
    // objectPositionY = 0,
    // setObjectPositionY,
    percentageY = 0,
    setPercentageY,
    imgHeightclass = "md:h-[500px] h-[260px]"
}) => {
    const [objectPositionY, setObjectPositionY] = useState(
        0); // Extract initial percentage

    const [isDragging, setIsDragging] = useState(false); // Track dragging state
    // const [startY, setStartY] = useState(0);

    // console.log(getImageDimensions(imageUrl))
    const imageRef = useRef(null);


    const handleMouseDown = (event) => {
        setIsDragging(true);
        document.body.style.cursor = 'grabbing'; // Change cursor to grabbing
        event.preventDefault(); // Prevent default behavior
        startY = event.clientY;
        imageStartY = objectPositionY;
        // const imageElement = imageRef.current;
        // if (!imageElement) return;

        // const rect = imageElement.getBoundingClientRect();
        // const dragY = event.clientY  // Calculate Y drag position relative to the image
        // console.log(dragY, rect.height)
        // const percentageY = Math.min(
        //     100,
        //     Math.max(0, (dragY / rect.height) * 100)
        // ); // Convert dragY to percentage, clamp 0-100
        // console.log(percentageY)
        // setObjectPositionY(percentageY.toFixed(2));
    };

    const handleMouseUp = (event) => {
        setIsDragging(false);
        // console.log(event)
        document.body.style.cursor = 'grab'; // Reset cursor to default
        event.preventDefault(); // Prevent default behavior
        // const imageElement = imageRef.current;
        // // if (!imageElement) return;
        // startY = event.clientY;
        // const rect = imageElement.getBoundingClientRect();
        // const dragY = event.clientY  // Calculate Y drag position relative to the image
        // console.log(dragY, rect.height)
        // const percentageY = Math.min(
        //     100,
        //     Math.max(0, (dragY / rect.height) * 100)
        // ); // Convert dragY to percentage, clamp 0-100
        // console.log(percentageY)
        // setObjectPositionY(percentageY.toFixed(2));
    };

    const handleMouseMove = (event) => {
        if (!isDragging) return; // Only move if dragging

        event.preventDefault(); // Prevent default behavior
        const imageElement = imageRef.current;
        if (!imageElement) return;
        // console.log(event)
        // Assuming drag start position is saved in a state variable (e.g., `startY`)
        const dragY = event.clientY - startY; // Calculate Y drag position relative to the start position
        // console.log(dragY, imageElement.getBoundingClientRect().height)

        const rect = imageElement.getBoundingClientRect();
        let newImgHeight = rect.width / imgRatio
        let potionY = imageStartY + dragY;

        // console.log(potionY, rect)

        if (potionY >= 0) potionY = 0;

        // console.log(potionY, (rect?.height - +newImgHeight))
        if (potionY <= (rect?.height - +newImgHeight)) potionY = (rect?.height - +newImgHeight);


        setObjectPositionY(potionY);
        const percentageYPos = (potionY / (rect?.height - +newImgHeight)) * 100;
        setPercentageY(percentageYPos?.toFixed(0));
        // const percentageY = Math.min(
        //     100,
        //     Math.max(0, (dragY / rect.height) * 100)
        // ); // Convert dragY to percentage, clamp 0-100
        // console.log(percentageY)

        // setObjectPositionY(percentageY.toFixed(2)); // Update state with 2-decimal precision
    };
    // console.log(objectPositionY)
    // Assuming you have a state to store the start Y position

    // You'd need to set the startY when the drag starts
    // const handleMouseDown = (event) => {
    //     setIsDragging(true);
    //     setStartY(event.clientY); // Store the initial Y position when dragging starts
    // };

    // // Reset drag on mouse up
    // const handleMouseUp = () => {
    //     setIsDragging(false);
    // };


    return (
        <>
            <div
                className={`relative ${imgHeightclass} w-full`}
            // onMouseMove={handleMouseMove} // Handle dragging
            // onMouseUp={handleMouseUp} // End dragging
            // onMouseLeave={handleMouseUp} // End dragging if mouse leaves
            >
                <div
                    draggable
                    className={`absolute left-0 top-0 ${imgHeightclass} w-full`}
                    // onMouseDown={handleMouseDown} // Start dragging
                    onMouseMove={handleMouseMove} // Handle dragging
                    onMouseUp={handleMouseUp} // End dragging
                    // onMouseLeave={handleMouseUp} // End dragging if mouse leaves
                    onMouseDown={handleMouseDown}
                // onDragEnter={handleMouseDown}
                // onDrag={handleMouseMove}
                // onDragEnd={handleMouseUp}
                >
                    <img
                        src={imageUrl}
                        alt={alt}
                        height="100%"
                        width="100%"
                        style={{
                            objectFit: 'cover',
                            objectPosition: `center ${percentageY}%`,
                            position: 'absolute',
                            height: '100%',
                            width: '100%',
                            left: '0',
                            top: '0',
                        }}
                        ref={imageRef}
                        onLoad={(e) => {
                            // console.log(e)
                            imgHeight = e.target.naturalHeight;
                            imgRatio = e.target.naturalWidth / e.target.naturalHeight;
                            // const img = new Image();
                            // imgHeight = imageRef?.current?.height;


                        }}
                    />
                </div>
            </div>
            {/* <p className="position-info">
                Current Y Position: <strong>{objectPositionY}</strong>
            </p> */}
        </>
    );
};

export default DraggableImage;


// import React, { useState, useRef } from 'react';

// /**
//  * DraggableImage Component
//  * Allows users to drag the image vertically to adjust the Y position for `object-position`.
//  */
// const DraggableImage = ({
//     imageUrl,
//     alt = 'drag',
//     objectFit = 'cover',
//     defaultPosition = 'center 50%',
// }) => {
//     const [objectPositionY, setObjectPositionY] = useState(
//         parseFloat(defaultPosition.split(' ')[1])
//     ); // Extract initial percentage (e.g., 50% from "center 50%")
//     const [isDragging, setIsDragging] = useState(false); // Track dragging state
//     const imageRef = useRef(null);

//     const initialY = useRef(0); // To store the mouse initial Y position when drag starts
//     const initialPosition = useRef(objectPositionY); // To store the image initial Y position

//     const handleMouseDown = (event) => {
//         setIsDragging(true); // Start dragging
//         initialY.current = event.clientY; // Store the initial mouse Y position
//         initialPosition.current = objectPositionY; // Store the initial image Y position
//         document.body.style.cursor = 'grabbing'; // Change cursor to grabbing
//         event.preventDefault(); // Prevent default behavior
//     };

//     const handleMouseUp = (event) => {
//         setIsDragging(false); // Stop dragging
//         document.body.style.cursor = 'grab'; // Reset cursor to default
//         event.preventDefault(); // Prevent default behavior
//     };

//     const handleMouseMove = (event) => {
//         if (!isDragging) return; // Only move if dragging

//         event.preventDefault(); // Prevent default behavior

//         const imageElement = imageRef.current;
//         if (!imageElement) return;

//         const rect = imageElement.getBoundingClientRect();

//         // Calculate how much the mouse has moved since the drag started
//         const deltaY = event.clientY - initialY.current;

//         // Calculate the new position by adding the movement to the initial position
//         const newPositionY = initialPosition.current + (deltaY / rect.height) * 100;

//         // Clamp the new position to between 0% and 100% to prevent overflow
//         const clampedPosition = Math.min(100, Math.max(0, newPositionY));

//         // Update the Y position of the image
//         setObjectPositionY(clampedPosition.toFixed(2)); // Update the Y position with the new value

//         // Update the initialY to the current mouse position for the next drag cycle
//         initialY.current = event.clientY;
//     };

//     return (
//         <>
//             <div className="relative md:h-[500px] h-[260px] w-full">
//                 <div
//                     className="absolute left-0 top-0 md:h-[500px] h-[260px] w-full"
//                     onMouseDown={handleMouseDown} // Start dragging
//                     onMouseMove={handleMouseMove} // Handle dragging
//                     onMouseUp={handleMouseUp} // End dragging
//                 >
//                     <img
//                         src={imageUrl}
//                         alt={alt}
//                         height="100%"
//                         width="100%"
//                         style={{
//                             objectFit: 'none',
//                             objectPosition: `center ${objectPositionY}%`,
//                             position: 'absolute',
//                             height: '100%',
//                             width: '100%',
//                             left: '0',
//                             top: '0',
//                         }}
//                         ref={imageRef}
//                     />
//                 </div>
//             </div>
//             <p className="position-info">
//                 Current Y Position: <strong>{objectPositionY}%</strong>
//             </p>
//         </>
//     );
// };

// export default DraggableImage;






