import React, { useEffect, useRef, useState } from "react";
import "../../asset/css/ckeditor5-editor.css";
import "../../asset/css/ckeditor5-content.css";
import "../../asset/css/ckeditor5.css";
import Services from "../../util/API/service";
import { Spin } from "antd";

const CKEditorComponent = ({ onChange = () => { }, name, value }) => {
    const editorRef = useRef();

    const { CKEditor, ClassicEditor } = editorRef.current || {};
    const [editorLoaded, setEditorLoaded] = useState(false);
    useEffect(() => {
        editorRef.current = {
            CKEditor: require("@ckeditor/ckeditor5-react").CKEditor, // v3+
            ClassicEditor: require("@ckeditor/ckeditor5-build-classic"),
        };
        setEditorLoaded(true);
    }, []);

    // Upload Adapter Function
    function uploadAdapter(loader) {
        return {
            upload: () => {
                return new Promise(async (resolve, reject) => {
                    try {
                        const file = await loader.file;
                        const formData = new FormData();
                        formData.append("image", file);
                        Services.post("/admin/blog/images", formData)
                            .then((result) => {
                                resolve({
                                    default: result.data?.data?.imageUrl, // Expected format for CKEditor
                                });
                            })
                            .catch((error) => {
                                reject("Upload failed");
                                console.error("Error:", error);
                            });
                    } catch (error) {
                        reject("Upload failed");
                        console.error("Error:", error);
                    }
                });
            },
            abort: () => {
                console.log("Upload aborted");
            },
        };
    }

    // Upload Plugin
    function uploadPlugin(editor) {
        editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
            return uploadAdapter(loader);
        };
    }

    return (
        <div>

            {editorLoaded ? (
                <CKEditor
                    name={name}
                    editor={ClassicEditor}
                    data={value}
                    config={{
                        // plugins: [],
                        extraPlugins: [uploadPlugin],
                        heading: {
                            options: [
                                { model: "paragraph", title: "Paragraph", class: "ck-heading_paragraph" },
                                { model: "heading1", view: "h1", title: "Heading 1", class: "ck-heading_heading1" },
                                { model: "heading2", view: "h2", title: "Heading 2", class: "ck-heading_heading2" },
                                { model: "heading3", view: "h3", title: "Heading 3", class: "ck-heading_heading3" },
                                // { model: "heading4", view: "h4", title: "Heading 4", class: "ck-heading_heading4" },
                                // { model: "heading5", view: "h5", title: "Heading 5", class: "ck-heading_heading5" },
                                // { model: "heading6", view: "h6", title: "Heading 6", class: "ck-heading_heading6" },
                            ],
                        },
                        image: {
                            resizeUnit: "px", // Resize images in pixels
                            toolbar: [
                                "imageStyle:alignLeft",
                                "imageStyle:alignCenter",
                                "imageStyle:alignRight",
                                "|",
                                "imageResize",
                                "imageTextAlternative",
                            ],
                            styles: ["alignLeft", "alignCenter", "alignRight"],
                        },
                    }}
                    onChange={(event, editor) => {
                        const data = editor.getData();
                        onChange(event, editor, data);
                    }}
                />
            ) : (
                <Spin className="w-full flex items-center justify-center" size="small" />
            )}
        </div>
    );
};

export default CKEditorComponent;
