import React, { useEffect } from "react";
import {
  AutoComplete,
  Button,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Image,
  Input,
  InputNumber,
  Radio,
  Row,
  Select,
  Space,
  Switch,
  TimePicker,
  Upload,
} from "antd";
import { PlusOutlined, CloseOutlined } from "@ant-design/icons";
import Label from "./Label";
import { UploadOutlined, InboxOutlined } from "@ant-design/icons";
import CONSTANTS from "../../util/constant/CONSTANTS";
import dayjs from "dayjs";
import FormList from "antd/es/form/FormList";
import TextEditor from "./Text-Editor";
import Heading from "./Heading";
import SelectWithSearch from "./select-with-search";
import { convertToPowerOf10 } from "../../util/functions";
import CitySatateCountry from "./City-Satate-Country";
import TextEditorSimple from "./Text-Editor-Simple";
// import TextEditorTiptap from "./Text-Editor-Novel.tsx";
// import TextEditorTiptap from "./Text-Editor-Novel";
// import GlobalCKEditor from "./Ck-Editor";
import CKEditorComponent from "./CKEditorComponent ";
// import TextEditorTiptap from "./Text-Editor-Novel";
// import TextEditorNovel from "./Text-Editor-Novel";

const FormFields = ({
  changedFields = {},
  formData = {},
  menuFields = [],
  formFields = [],
  form,
  disabled = false,
}) => {
  const AllFieldsData =
    formFields && Array.isArray(formFields) && formFields.length > 0
      ? formFields
      : menuFields;
  const getInputFormate = (data) => {
    const normFile = (e) => {
      if (Array.isArray(e)) {
        return e;
      }
      return e?.fileList;
    };
    switch (data.type) {
      case "date":
        const DateRules = [
          {
            required: data?.required,
            message: "Please select date!",
          },
        ];
        Array.isArray(data?.rule) &&
          data?.rule?.length > 1 &&
          DateRules.push(...data?.rule);
        return (
          <Form.Item
            name={data.name}
            id={data.id}
            className="form "
            // initialValue={
            //   data?.defaultValue ? dayjs(data?.defaultValue) : dayjs(new Date())
            // }
            required={data?.required}
            rules={DateRules}
          >
            <DatePicker
              showTime={data?.showTime ? { format: data?.showTime } : false}
              disabled={data?.disabled && formData[data?.name]}
              format={data?.format ?? "YYYY-MM-DD"}
              // disabledDate={(current) => current.isAfter(moment())}
              placeholder={data.placeholder ? data.placeholder : ""}
              style={{
                width: "100%",
              }}
            />
          </Form.Item>
        );
      case "time":
        const TimeRules = [
          {
            required: data?.required,
            message: "Please select date!",
          },
        ];
        Array.isArray(data?.rule) &&
          data?.rule?.length > 1 &&
          TimeRules.push(...data?.rule);
        return (
          <Form.Item
            name={data?.name}
            id={data?.id}
            className="form "
            // initialValue={
            //   data?.defaultValue
            //     ? dayjs(moment(data?.defaultValue))
            //     : dayjs(moment())
            // }
            rules={TimeRules}
          >
            <TimePicker
              showTime={data?.showTime}
              disabled={data?.disabled && formData[data?.name]}
              // placeholder={data.placeholder ? data.placeholder : ""}
              style={{
                width: "100%",
              }}
              format={data?.format ? data?.format : "HH:mm:ss"}
            />
          </Form.Item>
        );
      case "autocomplete":
        const AutoCompleteRules = [
          {
            type: "text",
            required: data?.required,
            message: "Please input date!",
          },
        ];
        Array.isArray(data?.rule) &&
          data?.rule?.length > 1 &&
          AutoCompleteRules.push(...data?.rule);
        return (
          <Form.Item
            name={data.name}
            id={data.id}
            className="form "
            rules={AutoCompleteRules}
          >
            <AutoComplete
              disabled={data?.disabled && formData[data?.name]}
              options={data?.option}
              filterOption={(inputValue, option) =>
                option?.value
                  ?.toUpperCase()
                  ?.indexOf(inputValue?.toUpperCase()) !== -1
              }
            />
          </Form.Item>
        );
      case "select":
        const SelectRules = [
          {
            required: data?.required,
            message: "Please select Valid " + data.Label,
          },
        ];
        Array.isArray(data?.rule) &&
          data?.rule?.length > 1 &&
          SelectRules.push(...data?.rule);
        return (
          <>
            <Form.Item
              name={data.name}
              id={data.id}
              className="form "
              initialValue={data?.defaultValue}
              hasFeedback
              rules={SelectRules}
            >
              <Select
                disabled={data?.disabled && formData[data?.name]}
                showSearch
                mode={data?.mode}
                placeholder={data.placeholder ? data.placeholder : ""}
                filterOption={(inputValue, option) => {
                  const value = option?.children?.props?.children[0]?.props
                    ?.children
                    ? `${option?.children?.props?.children[0]?.props?.children}`
                    : `${option?.children}`;
                  return value
                    ?.toLowerCase()
                    ?.includes(inputValue?.toLowerCase());
                }}
                allowClear // Allow clearing the selected value
              >
                {data.option &&
                  data.option.length > 0 &&
                  data.option.map((item) => (
                    <Select.Option key={`role_${item.id}`} value={item.value}>
                      {item.label ? item.label : item.value}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
          </>
        );
      case "custom-beach-select":
        const customSelectRules = [
          {
            required: data?.required,
            message: "Please select Valid " + data.Label,
          },
        ];
        Array.isArray(data?.rule) &&
          data?.rule?.length > 1 &&
          SelectRules.push(...data?.rule);
        return (
          <>
            <Form.Item
              name={data.name}
              id={data.id}
              className="form "
              initialValue={data?.defaultValue}
              hasFeedback
              rules={customSelectRules}
            >
              <Select
                disabled={data?.disabled && formData[data?.name]}
                showSearch
                mode={data?.mode}
                placeholder={data.placeholder ? data.placeholder : ""}
                filterOption={(inputValue, option) => {
                  const value = option?.children?.props?.children[0]?.props
                    ?.children
                    ? `${option?.children?.props?.children[0]?.props?.children}`
                    : `${option?.children}`;
                  return value
                    ?.toLowerCase()
                    ?.includes(inputValue?.toLowerCase());
                }}
                allowClear // Allow clearing the selected value
              >
                {data.option &&
                  data.option.length > 0 &&
                  data.option.map((item) => (
                    <Select.Option key={`role_${item.id}`} value={item.value}>
                      {item.label ? item.label : item.value}
                    </Select.Option>
                  ))}
              </Select>
            </Form.Item>
          </>
        );
      case "selectSearch":
        const SelectRule = [
          {
            required: data?.required,
            message: "Please select Valid " + data.Label,
          },
        ];
        Array.isArray(data?.rule) &&
          data?.rule?.length > 1 &&
          SelectRule.push(...data?.rule);
        return (
          <Form.Item
            name={data.name}
            id={data.id}
            className="form "
            initialValue={data?.defaultValue ?? formData[data?.name]}
            hasFeedback
            rules={SelectRule}
          >
            <SelectWithSearch
              data={data}
              formData={formData}
              setFormData={form.setFieldsValue}
            />
          </Form.Item>
        );
      case "dragupload":
        return (
          <Form.Item className="form mt-2">
            <Form.Item
              name={data.id}
              className="form "
              valuePropName="fileList"
              getValueFromEvent={normFile}
              noStyle
              rules={[
                {
                  required: data?.required,
                  message: "Please Enter valid " + data.Label,
                },
              ]}
            >
              <Upload.Dragger
                name={data.name}
                id={data.id}
                disabled={data?.disabled && formData[data?.name]}
                beforeUpload={() => {
                  return false;
                }}
              >
                <p className="ant-upload-drag-icon">
                  <InboxOutlined />
                </p>
                <p className="ant-upload-text">
                  Click or drag file to this area to upload
                </p>
                <p className="ant-upload-hint">
                  Support for a single or bulk upload.
                </p>
              </Upload.Dragger>
            </Form.Item>
          </Form.Item>
        );
      case "file":
        return (
          <Form.Item
            name={data.name}
            className="form "
            id={data.id}
            valuePropName="fileList"
            getValueFromEvent={normFile}
            rules={[
              {
                required: data?.required,
                message: "Please Enter valid " + data.Label,
              },
            ]}
          >
            <Upload
              name="logo"
              disabled={data?.disabled && formData[data?.name]}
              listType="picture"
              accept={data?.acceptFormat}
              maxCount={1}
              beforeUpload={() => {
                return false;
              }}
            >
              <Button icon={<UploadOutlined />}>Click to upload</Button>
            </Upload>
          </Form.Item>
        );
      case "ImageCenterPreview":
        return (
          <div className=" flex justify-center">
            <Image
              className=" aspect-video"
              height={200}
              src={`${formData[data?.image]}`}
            />
          </div>
        );
      case "fileWithPreview":
        return (
          <div className="tw-gap-3">
            {formData[data?.name] && !Array.isArray(formData[data?.name]) && <div sm={3}>
              <div className="flex justify-center items-center my-3">
                <Image
                  width={150}
                  height={150}
                  src={`${formData[data?.name]}`}
                />
              </div>
            </div>}
            <div >
              <Form.Item
                name={data.name}
                className="form mt-2"
                id={data.id}
                // valuePropName="fileList"
                getValueFromEvent={normFile}
                rules={[
                  {
                    required: data?.required,
                    message: "Please Enter valid " + data.Label,
                  },
                ]}
              >
                <Upload
                  name="logo"
                  disabled={data?.disabled && formData[data?.name]}
                  listType="picture"
                  accept={data?.acceptFormat}
                  maxCount={data?.maxCount ? data?.maxCount : 1}
                  beforeUpload={() => {
                    return false;
                  }}
                  multiple={data?.maxCount ? true : false}
                >
                  <Button icon={<UploadOutlined />}>Click to upload</Button>
                </Upload>
              </Form.Item>
            </div>

            {/* <Col sm={21}>
              <Form.Item
                name={data.name}
                className="form mt-2"
                id={data.id}
                // valuePropName="fileList"
                getValueFromEvent={normFile}
                rules={[
                  {
                    required: data?.required,
                    message: "Please Enter valid " + data.Label,
                  },
                ]}
              >
                <Upload
                  name="logo"
                  disabled={data?.disabled && formData[data?.name]}
                  listType="picture"
                  accept={data?.acceptFormat}
                  maxCount={data?.maxCount ? data?.maxCount : 1}
                  beforeUpload={() => {
                    return false;
                  }}
                  multiple={data?.maxCount ? true : false}
                >
                  <Button icon={<UploadOutlined />}>Click to upload</Button>
                </Upload>
              </Form.Item>
            </Col>
            {formData[data?.name] && !Array.isArray(formData[data?.name]) && <Col sm={3}>
              <div className="flex justify-end">
                <Image
                  width={60}
                  height={60}
                  src={`${formData[data?.name]}`}
                />
              </div>
            </Col>} */}
          </div>
        );
      case "fileOrLinkWithPreview":
        return (
          <div className="tw-gap-3">
            {formData[data?.name] && !Array.isArray(formData[data?.name]) && <div sm={3}>
              <div className="flex justify-center items-center my-3">

                <Image
                  width={150}
                  height={150}
                  src={`${formData[data?.name] ?? formData[data?.link]}`}
                />
              </div>
            </div>}
            <Form.Item shouldUpdate>
              {({ getFieldValue }) => {
                const imageUrl = getFieldValue(data?.link);
                const imgFile = getFieldValue(data?.name);
                // if (!imageUrl) return null;
                // const image = isFlickrUrl(imageUrl) ? photoUrl : imageUrl;
                return (
                  <Col xs={24} className="flex justify-center items-center">
                    <div className="aspect-video w-[400px] flex items-center justify-center">
                      {imgFile && imgFile?.[0]?.originFileObj && <Image src={URL?.createObjectURL(imgFile?.[0]?.originFileObj)} />}
                      {!imgFile?.[0]?.originFileObj && imageUrl && <Image src={imageUrl} />}

                    </div>
                  </Col>
                );
              }}
            </Form.Item>
            <div>

              <Form.Item
                name={data.name}
                className="form mt-2"
                id={data.id}
                // valuePropName="fileList"
                getValueFromEvent={normFile}
                rules={[
                  {
                    required: data?.required,
                    message: "Please Enter valid " + data.Label,
                  },
                ]}
              >
                <Upload
                  name="logo"
                  disabled={data?.disabled && formData[data?.name]}
                  listType="picture"
                  accept={data?.acceptFormat}
                  maxCount={data?.maxCount ? data?.maxCount : 1}
                  beforeUpload={() => {
                    return false;
                  }}
                  multiple={data?.maxCount ? true : false}
                >
                  <Button icon={<UploadOutlined />}>Click to upload</Button>
                </Upload>
              </Form.Item>
            </div>
            <div>
              <Label required={data.required ?? data?.rule?.length}>
                {data.linkLabel}
              </Label>
              <Form.Item
                name={data.link}
                className="form mt-2"
                id={data.id}
                required={data?.required}
                rules={[
                  {
                    required: data?.required,
                    message: "Please Enter Valid " + data.linkLabel,
                  },
                  {
                    type: "url",
                    message: `${data?.linkLabel} is not a valid url.`,
                  },
                ]}
              >
                <Input
                  disabled={data?.disabled && formData[data?.link]}
                  placeholder={data.placeholder ? data.placeholder : ""}
                  initialvalues={formData && formData[data.link]}
                  // ref={inputRef}
                  autoFocus={data?.autoFocus ? data?.autoFocus : false}
                />
              </Form.Item>
            </div>
          </div>
        );
      case "number":
        const NumberRules = [
          {
            required: data?.required,
            message: "Please Enter valid " + data.Label,
          },
          // {
          //   type: data.type,
          //   message: "Please Enter valid Number",
          // },
        ];
        const NumberOthers = data?.Other || {};
        Array.isArray(data?.rule) &&
          data?.rule?.length &&
          NumberRules.push(...data?.rule);
        return (
          <Form.Item
            name={data.name}
            className="form "
            rules={NumberRules}
          // validateStatus={form.getFieldError(data.name) ? "error" : ""}
          // help={
          //   form.getFieldError(data.name)
          //     ? form.getFieldError(data.name)[0]
          //     : ""
          // }
          >
            <InputNumber
              disabled={data?.disabled && formData[data?.name]}
              placeholder={data.placeholder ? data.placeholder : ""}
              controls={false}
              style={{
                width: "100%",
              }}
              step={data?.step || "1"}
              value={formData && formData[data.id]}
              {...NumberOthers}
            />
          </Form.Item>
        );
      case "mobile":
        return (
          <Form.Item
            name={data.name}
            className="form "
            rules={[
              // {
              //   required: data?.required,
              //   message: "Please Enter valid mobile number",
              // },
              {
                type: data.type,
                message: "Please Enter valid Number",
              },
              () => ({
                validator(_, value) {
                  // console.log(value);
                  if (value && /^\d{10}$/.exec(value)) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error("Please Enter valid mobile number")
                  );
                },
              }),
            ]}
            validateStatus={form.getFieldError(data.name) ? "error" : ""}
            help={
              form.getFieldError(data.name)
                ? form.getFieldError(data.name)[0]
                : null
            }
          >
            <InputNumber
              disabled={data?.disabled && formData[data?.name]}
              placeholder={data.placeholder ? data.placeholder : ""}
              controls={false}
              style={{
                width: "100%",
              }}
              value={formData && formData[data.id]}
              pattern="[1-9]{1}[0-9]{9}"
              maxLength={10}
            />
          </Form.Item>
        );
      case "checkbox":
        return (
          <Form.Item
            name={data.name}
            className="form "
            id={data.id}
            required={data?.required}
            valuePropName="checked"
            wrapperCol={{
              offset: 8,
              span: 16,
            }}
          >
            <Checkbox disabled={data?.disabled && formData[data?.name]}>
              {data.Label}
            </Checkbox>
          </Form.Item>
        );
      case "textarea":
        return (
          <Form.Item
            name={data.name}
            className="form "
            rules={[
              {
                required: data?.required,
                message: "Enter Valid " + data.Label,
              },
              // data.rule && data.rule,
            ]}
          >
            <Input.TextArea
              disabled={data?.disabled && formData[data?.name]}
              placeholder={data.placeholder ? data.placeholder : ""}
              initialvalues={
                formData && data.type !== "file" ? formData[data.name] : ""
              }
            />
          </Form.Item>
        );
      case "richTextarea":
        return (
          <Form.Item
            name={data.name}
            className="form "
            style={{ minHeight: "250px" }}
            rules={[
              {
                required: data?.required,
                message: "Enter Valid " + data.Label,
              },
              // data.rule && data.rule,
            ]}
          >
            <TextEditor />
          </Form.Item>
        );
      case "SimpleRichTextArea":
        return (
          <Form.Item
            name={data?.name}
            id={data?.id}
            className="form mt-2"
            // style={{ height: "250px" }}
            required={true}
            rules={[
              {
                required: data?.required,
                message: "Enter Valid " + data.Label,
              },

              // // ({ getFieldValue }) => ({
              // //   validator(_, value) {
              // //     if (data?.required && (value === "<p><br></p>" || !value || value.trim() === "")) {
              // //       return Promise.reject(new Error("This field is required"));
              // //     }
              // //     return Promise.resolve();
              // //   },
              // // }),
              // data.rule && data.rule,
            ]}
          // initialValue={form.getFieldValue()}
          >
            {/* <GlobalCKEditor
              initData="<p>Start editing!</p>"
              onChange={() => {
                console.log('Editor Data:', data);
              }}
              config={{
                toolbar: [
                  ['Bold', 'Italic', 'Underline'],
                  ['NumberedList', 'BulletedList'],
                  ['Link', 'Unlink'],
                ],
              }}
            /> */}
            <CKEditorComponent
              name="description"
              value={formData?.[data?.name]}
              onChange={(event, editor) => {
                const newData = editor.getData();
                // console.log(newData, "jngjg")
                form.setFieldsValue({ [data?.name]: newData });
              }}
            />
            {/* <div className="mt-8">
              <TextEditorSimple valueB={formData?.[data?.name]} />
            </div> */}
            {/* <TextEditorTiptap value={formData?.[data?.name]}
              onChange={(value) => {
                console.log(value, "first");
              }}
            /> */}

          </Form.Item>
        );
      case "password":
        const rulePass = [
          {
            required: data?.required,
            message: "Enter Valid " + data.Label,
          },
        ];
        data.rule && rulePass.push(data.rule);
        return (
          <Form.Item
            name={data.name}
            className="form "
            rules={[
              {
                required: data?.required,
                message: "Enter Valid " + data.Label,
              },
            ]}
          >
            <Input.Password />
          </Form.Item>
        );
      case "radio":
        return (
          <Form.Item name={data.name} id={data.id} required className="!mb-0">
            <Radio.Group disabled={data?.disabled && formData[data?.name]}>
              {/* <Space direction="vertical"> */}
              <Space>
                {data.option.map((el) => (
                  <Radio
                    value={el.value}
                    id={el.id}
                    key={el.id}
                    className="form-modal-title-items"
                  >
                    {el.Label}
                  </Radio>
                ))}
              </Space>
            </Radio.Group>
          </Form.Item>
        );
      case "switch":
        return (
          <Form.Item
            id={data?.id}
            initialValue={false}
            name={data?.name}
            className="mb-0"
          >
            <Switch
              className=" mb-0"
              // checked={(formData && formData[data.name]) || false}
              disabled={
                data?.disabled &&
                (formData[data?.name] === false ||
                  formData[data?.name] === true)
              }
              defaultChecked={(formData && formData[data.name]) || false}
            />
          </Form.Item>
        );
      case "extraMultiSingle":
        return (
          <FormList
            name={data?.name}
            id={data?.id}
            initialValue={[{}]}
            // initialValue={[
            //   {
            //     title: "t 1",
            //     shortDescription: "sd 1",
            //     description: "d 1",
            //     languageId: 2,
            //   },
            //   {
            //     title: "t 3",
            //     shortDescription: "sd 3",
            //     description: "d 3",
            //     languageId: 3,
            //   },
            //   {
            //     title: "t 4",
            //     shortDescription: "sd 4",
            //     description: "d4",
            //     languageId: 4,
            //   },
            // ]}
            required={data?.required}
          >
            {(fields) => (
              <>
                {fields.map((field, index) => (
                  <>
                    {CONSTANTS.FORM_FIELD[data?.menu].map((dataField) =>
                      getInputFormate({
                        ...dataField,
                        name: [field.name, dataField.name],
                        id: [field.id, dataField.id],
                        key: field.key,
                      })
                    )}
                  </>
                ))}
              </>
            )}
          </FormList>
        );
      case "citystatecountry":
        return (
          <>
            <CitySatateCountry
              disabled={disabled}
              RequiredObject={
                data?.RequiredObject || {
                  cityId: true,
                  stateId: true,
                  countryId: true,
                  islandId: true,
                }
              }
              VisibleObject={
                data?.VisibleObject || {
                  cityId: true,
                  stateId: true,
                  countryId: true,
                  islandId: true,
                }
              }
              label={data?.label ?? false}
            />
          </>
        );
      case "multifield2":
        return (
          <div className="">
            <FormList
              name={data?.name}
              id={data?.id}
              initialValue={data?.initialValue}
              // initialValue={[
              //   {
              //     title: "t 1",
              //     shortDescription: "sd 1",
              //     description: "d 1",
              //     languageId: 2,
              //   },
              //   {
              //     title: "t 3",
              //     shortDescription: "sd 3",
              //     description: "d 3",
              //     languageId: 3,
              //   },
              //   {
              //     title: "t 4",
              //     shortDescription: "sd 4",
              //     description: "d4",
              //     languageId: 4,
              //   },
              // ]}
              rules={[
                {
                  required: data?.required,
                  message: `Please enter ${data?.Label}`,
                },
                // data.rule && data.rule,
              ]}
              required={data?.required}
            >
              {(fields, { add, remove }, { errors }) => (
                <>
                  {fields.map((field, index) => (
                    <>
                      {/* <Heading>{data?.menuLabel}</Heading> */}
                      <Row className="gap-x-2">
                        {CONSTANTS.FORM_FIELD[data?.menu]?.map((dataField) => (
                          <>
                            <Col
                              sm={
                                (dataField?.col ? dataField?.col : data?.col) ||
                                11
                              }
                              style={{
                                marginTop: dataField?.boxMargin || "0px",
                              }}
                            >
                              {/* {dataField.Label && (
                                <Label required={dataField.required}>
                                  {dataField.Label}
                                </Label>
                              )} */}
                              {getInputFormate({
                                ...dataField,
                                name: [field.name, dataField.name],
                                id: [field.id, dataField.id],
                                key: field.key,
                                val: `${index + 1}`,
                              })}
                            </Col>
                          </>
                        ))}
                        <Col sm={1}>
                          {/* {data?.closeIconMargin} */}
                          <Form.Item
                            key={field.key}
                            className={`mb-3`}
                            style={{
                              marginTop: data?.closeIconMargin || "0px",
                            }}
                          >
                            {" "}
                            <Button onClick={() => remove(field.name)}>
                              <p className=" text-black hover:text-black">
                                {" "}
                                {data?.removeName || <CloseOutlined />}
                              </p>
                            </Button>
                          </Form.Item>
                        </Col>
                      </Row>
                    </>
                  ))}

                  <Button onClick={() => add()} className={``}>
                    <p className=" text-black hover:text-black mb-1">
                      {" "}
                      <PlusOutlined /> {`Add ${data?.Label}` || "Add Field"}
                    </p>
                  </Button>
                  <span className="text-red-500 block ">{errors}</span>
                </>
              )}
            </FormList>
          </div>
        );

      case "multifield":
        return (
          <div className="ml-2">
            <FormList
              name={data?.name}
              id={data?.id}
              initialValue={data?.initialValue}
              // initialValue={[
              //   {
              //     title: "t 1",
              //     shortDescription: "sd 1",
              //     description: "d 1",
              //     languageId: 2,
              //   },
              //   {
              //     title: "t 3",
              //     shortDescription: "sd 3",
              //     description: "d 3",
              //     languageId: 3,
              //   },
              //   {
              //     title: "t 4",
              //     shortDescription: "sd 4",
              //     description: "d4",
              //     languageId: 4,
              //   },
              // ]}
              required={data?.required}
            >
              {(fields, { add, remove }) => (
                <>
                  {fields.map((field, index) => (
                    <>
                      <Heading>{data?.menuLabel}</Heading>
                      {data?.AllFields?.map((dataField) => (
                        <>
                          <Label required={dataField.required}>
                            {dataField.Label}
                          </Label>
                          {getInputFormate({
                            ...dataField,
                            name: [field.name, dataField.name],
                            id: [field.id, dataField.id],
                            key: field.key,
                          })}
                        </>
                      ))}
                      <Form.Item key={field.key} className="mt-2">
                        <Button onClick={() => remove(field.name)}>
                          {data?.removeName || "Remove Field"}
                        </Button>
                      </Form.Item>
                    </>
                  ))}
                  <Button className="-mt-2" onClick={() => add()}>
                    {data?.addName || "Add Field"}
                  </Button>
                </>
              )}
            </FormList>
          </div>
        );
      case "url":
        return (
          <Form.Item
            name={data.name}
            className="form mt-2"
            id={data.id}
            required={data?.required}
            rules={[
              {
                required: data?.required,
                message: "Please Enter Valid " + data.Label,
              },
              {
                type: "url",
                message: `${data?.Label} is not a valid url.`,
              },
            ]}
          >
            <Input
              disabled={data?.disabled && formData[data?.name]}
              placeholder={data.placeholder ? data.placeholder : ""}
              initialvalues={formData && formData[data.name]}
              // ref={inputRef}
              autoFocus={data?.autoFocus ? data?.autoFocus : false}
            />
          </Form.Item>
        );
      default:
        const rule = [
          {
            required: data?.required,
            message: "Please Enter Valid " + data.Label,
          },
        ];
        data.type !== "text" &&
          rule.push({
            type: data.type,
          });
        Array.isArray(data?.rule) &&
          data?.rule?.length &&
          rule.push(...data?.rule);
        // const rule = [
        // {
        //   required: data?.required,
        //   message: "Please Enter Valid " + data.Label,
        // },
        // data.type !== "text" && {
        //   type: data.type,
        // },
        // ];
        // data.rule && rule.push(data.rule);
        const TextOther = data?.Other || {};
        return (
          <Form.Item
            name={data.name}
            className="form "
            id={data.id}
            required={data?.required}
            rules={rule}
          >
            <Input
              disabled={data?.disabled && formData[data?.name]}
              placeholder={data.placeholder ? data.placeholder : ""}
              initialvalues={formData && formData[data.name]}
              {...TextOther}
            />
          </Form.Item>
        );
    }
  };

  useEffect(() => {
    if (form) {
      form.resetFields();
      if (Object.keys(formData).length) {
        const Fields = [];
        AllFieldsData.forEach((el) => {
          if (el.item) {
            Fields.push(el.item[0]);
            Fields.push(el.item[1]);
          } else {
            Fields.push(el);
          }
        });

        Fields.filter((el) => el?.type === "number").forEach((el) => {
          if (el?.step) {
            return (formData[el.name] = parseFloat(formData[el.name]));
          }
          formData[el.name] = parseInt(formData[el.name], 10);
        });
        Fields.filter((el) => el?.type === "date").forEach((el) => {

          formData[el.name] = formData[el.name] ? dayjs(formData[el.name]) : "";
        });
        Fields.filter((el) => el?.type === "time").forEach((el) => {
          formData[el.name] = dayjs(formData[el.name], "HH:mm:ss");
        });

        Fields.filter(
          (el) => el?.type === "file" || el?.type === "dragupload"
        ).forEach((el) => {
          delete formData[el.name];
        });
        // console.log(formData, "GENERATED DATA SHOW ");
        form.setFieldsValue(formData);
        // console.log(formData);
      }
    }
  }, [form, formData, AllFieldsData]);

  return (
    <Form
      form={form}
      disabled={disabled}
      name="form_in_modal"
      scrollToFirstError
      onFieldsChange={(value) => {
        changedFields[value?.[0]?.name] = value[0].value;
        // console.log(changedFields, "changed");
      }}
    >
      <Row className="items-center justify-between" gutter={[16, 0]}>
        {AllFieldsData.map((data) => {
          return (
            <Col
              key={data?.id}
              span={data?.width || 24}
              style={{ marginTop: "10px", marginBottom: "10px" }}
            >
              <Row>
                {data?.labelwidth ||
                  (data.Label && (
                    <Col
                      span={24}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "flex-start",
                      }}
                    >
                      <Label required={data.required ?? data?.rule?.length}>
                        {data.Label}
                      </Label>
                    </Col>
                  ))}
                <Col span={24}>{getInputFormate(data)}</Col>
                {data?.extraBelow}
              </Row>
            </Col>
          );
        })}
      </Row>
    </Form>
  );
};

export default FormFields;
