import { Button, Divider, Modal, Tabs } from "antd";
import React, { useState } from "react";
import LocationImageUpload from "./LocationImageUpload";
import { apiGenerator } from "../../../util/functions";
import CONSTANTS from "../../../util/constant/CONSTANTS";
import LocationImageSelect from "./LocationImageSelect";
import FlickerImageSelect from "./FlickerImageSelect";
import LocationUnsplash from "./LocationUnsplash";
// import GooglePhoto from "../Beach/Image/GooglePhoto";
import GooglePhotoLocation from "./GooglePhoto-Location";
import LinkUploadLocationImage from "./LinkUploadLocationImage";

const AddLocationImage = ({
  locationData = {},
  refresher = () => { },
  type = "Country",
  API,
}) => {
  const ModalRefresher = () => {
    setOpen(false);
    refresher();
  };
  const [open, setOpen] = useState(false);
  //   const RefreshAllDone = () => {
  //     refresher();
  //     setOpen(false);
  //   };
  const APIKEY = {
    State: "stateId",
    City: "cityId",
    Country: "countryId",
    Island: "islandId",
    sharkSpeciesId: "sharkSpeciesId",
  };
  return (
    <>
      <Button
        onClick={() => {
          setOpen(true);
        }}
        type="primary"
      >
        {locationData?.imageId ? "Edit" : "Add"} Cover Photo
      </Button>
      <Modal
        open={open}
        onCancel={() => {
          setOpen(false);
          refresher();
        }}
        width={1000}
        centered
        cancelButtonProps={{ style: { display: "none" } }}
        okButtonProps={{ style: { display: "none" } }}
      >
        <h1 className=" text-center">
          {locationData?.imageId ? "Edit" : "Add"} Cover Photo
        </h1>
        <Divider />
        <Tabs
          defaultActiveKey="1"
          type="card"
          centered
          tabBarStyle={{ marginBottom: "0" }}
          tabBarGutter={12}
          items={[
            {
              label: `Upload`,
              key: 6,
              children: (
                <LocationImageUpload
                  locationData={locationData}
                  refresher={ModalRefresher}
                  type={type}
                  API={API}
                  onSuccess={(formData) => {
                    if (CONSTANTS.API?.[type]?.update) {
                      const UpadteLocationDetail = apiGenerator(
                        CONSTANTS.API?.[type]?.update,
                        {
                          id: locationData?.id,
                        }
                      );
                      //  payload.AllBeachId = beachData?.id;
                      API.sendRequest(
                        UpadteLocationDetail,
                        (res) => {
                          ModalRefresher();
                        },
                        formData
                      );
                    }
                  }}
                />
              ),
              // <Flicker beachData={beachData} refresher={RefreshAllDone} />
            },

            {
              label: `Google Photo`,
              key: 2,
              children: (
                <GooglePhotoLocation
                  locationData={locationData}
                  refresher={ModalRefresher}
                  type={type}
                  API={API}
                  onSuccess={(formData) => {
                    const UpdateLocationDetail = apiGenerator(
                      { type: "POST", endpoint: "/admin/image/addForLocation" },
                      {},
                      `${APIKEY?.[type]
                        ? `?${APIKEY?.[type]}=${locationData?.id}`
                        : ""
                      }`
                    );
                    API.sendRequest(
                      UpdateLocationDetail,
                      (res) => {
                        ModalRefresher();
                      },
                      formData,
                      "Update Image Successfully"
                    );
                  }}
                />
              ),
            },
            ,
            {
              label: `Select Existing`,
              key: 7,
              children: (
                <LocationImageSelect
                  locationData={locationData}
                  refresher={ModalRefresher}
                  type={type}
                  API={API}
                  onSuccess={(formData) => {
                    if (CONSTANTS.API?.[type]?.update) {
                      const UpadteLocationDetail = apiGenerator(
                        CONSTANTS.API?.[type]?.update,
                        {
                          id: locationData?.id,
                        }
                      );
                      //  payload.AllBeachId = beachData?.id;
                      API.sendRequest(
                        UpadteLocationDetail,
                        (res) => {
                          ModalRefresher();
                        },
                        formData,
                        "Update Image Successfully"
                      );
                    }
                  }}
                />
              ),
            },

            {
              label: `Flicker`,
              key: 8,
              children: (
                <FlickerImageSelect
                  locationData={locationData}
                  refresher={ModalRefresher}
                  type={type}
                  API={API}
                  onSuccess={(formData) => {
                    // if (CONSTANTS.API?.[type]?.update) {
                    //   const UpadteLocationDetail = apiGenerator(
                    //     CONSTANTS.API?.[type]?.update,
                    //     {
                    //       id: locationData?.id,
                    //     }
                    //   );
                    //  payload.AllBeachId = beachData?.id;
                    const UpadteLocationDetail = apiGenerator(
                      {
                        type: "POST",
                        endpoint: "/admin/image/addForLocation",
                      },
                      {},
                      `${APIKEY?.[type]
                        ? `?${APIKEY?.[type]}=${locationData?.id}`
                        : ""
                      }`
                    );
                    API.sendRequest(
                      UpadteLocationDetail,
                      (res) => {
                        ModalRefresher();
                      },
                      formData,
                      "Update Image Successfully"
                    );
                    // }
                  }}
                />
              ),
            },

            {
              label: `Unsplash`,
              key: 9,
              children: (
                <LocationUnsplash
                  locationData={locationData}
                  refresher={ModalRefresher}
                  type={type}
                  API={API}
                  onSuccess={(formData) => {
                    // if (CONSTANTS.API?.[type]?.update) {
                    //   const UpadteLocationDetail = apiGenerator(
                    //     CONSTANTS.API?.[type]?.update,
                    //     {
                    //       id: locationData?.id,
                    //     }
                    //   );
                    //  payload.AllBeachId = beachData?.id;
                    const UpadteLocationDetail = apiGenerator(
                      {
                        type: "POST",
                        endpoint: "/admin/image/addForLocation",
                      },
                      {},
                      `${APIKEY?.[type]
                        ? `?${APIKEY?.[type]}=${locationData?.id}`
                        : ""
                      }`
                    );
                    API.sendRequest(
                      UpadteLocationDetail,
                      (res) => {
                        ModalRefresher();
                      },
                      formData,
                      "Update Image Successfully"
                    );
                    // }
                  }}
                />
              ),
            },
            {
              label: `Link Upload Photo`,
              key: 5,
              children: (
                <LinkUploadLocationImage
                  locationData={locationData}
                  refresher={ModalRefresher}
                  API={API}
                  onSuccess={(formData, form) => {
                    const UpadteLocationDetail = apiGenerator(
                      {
                        type: "POST",
                        endpoint: "/admin/image/addForLocation",
                      },
                      {},
                      `${APIKEY?.[type]
                        ? `?${APIKEY?.[type]}=${locationData?.id}`
                        : ""
                      }`
                    );
                    API.sendRequest(
                      UpadteLocationDetail,
                      (res) => {
                        ModalRefresher();
                        form?.resetFields();
                      },
                      formData,
                      "Update Image Successfully"
                    );
                  }}
                />
              ),
            },
          ]}
        />
      </Modal>
    </>
  );
};

export default AddLocationImage;
