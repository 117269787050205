import React, { useEffect, useState } from 'react'
import { apiGenerator, EditorContent, FinalImageGenerator } from '../../../../../util/functions';
import CONSTANTS from '../../../../../util/constant/CONSTANTS';
import { payloadGenerator } from '../../../../../component/common/CRUD-Component';
import useHttp from '../../../../../hooks/use-http';
import { useNavigate, useParams } from 'react-router-dom';
import { appRoot, ROUTES } from '../../../../../util/Route';
import { Breadcrumb, Button, Card, Form, Image, Modal, Row, Typography } from 'antd';
import DraggableImage from '../../../../../component/common/drag-image';
import FormFields from '../../../../../component/common/FormFields';
import AddLocationImage from '../../../../../component/common/Location/Add-Location-Image';

const SharkSpeciesDetail = () => {

    const [isEditing, setIsEditing] = useState(false);
    const [isRepositionEdit, setIsRepositionEdit] = useState(false);
    const [intialSharkSpeciesData, setInitialSharkSpeciesData] = useState({});
    const [editedData, setEditedData] = useState({});
    const [refresh, setRefresh] = useState(true);
    const param = useParams();
    const navigate = useNavigate();
    const API = useHttp();
    const [percentageY, setPercentageY] = useState(
        0); // Extract initial percentage
    const handleEdit = () => {
        setIsEditing(true);
    };
    useEffect(() => {
        const BasicDetailsAPI = apiGenerator(
            CONSTANTS.API.SharkSpecies.getOne, {}
            ,            // { id: param?.sharkSpeciesSlug },{}
            `${param?.sharkSpeciesSlug}`
        );
        API.sendRequest(BasicDetailsAPI, (res) => {
            if (!res?.data) {
                navigate(`${appRoot}/${ROUTES.SHARK}/${ROUTES.CATEGORY}`);
            }
            const data = res?.data?.sharkSpecies
            setEditedData({
                ...data,
                image: data?.image?.imageUrl
            });
            data?.image?.position?.objectPositionY && setPercentageY(data?.image?.position?.objectPositionY ?? 0)
            setInitialSharkSpeciesData(data);
        });
    }, [refresh]);

    const handleFormSubmit = () => {
        form
            .validateFields()
            .then((values) => {
                // let payload = getClearObject(values);
                console.log(values)
                const forPayload = payloadGenerator(
                    values,
                    [
                        ...CONSTANTS.FORM_FIELD.EDIT_SHARKSPECIES_DETAIL,
                        ...(isEditing
                            ? [
                                {
                                    no: 7,
                                    Label: "description",
                                    name: "description",
                                    id: "description",
                                    type: "SimpleRichTextArea",
                                    placeholder: "Enter description",
                                },
                            ]
                            : []),
                    ],
                    true
                );

                const UpadteSharkSpeciesDetail = apiGenerator(CONSTANTS.API.SharkSpecies.update, {

                }, `${intialSharkSpeciesData?.id}`);
                //  payload.AllBeachId = beachData?.id;
                API.sendRequest(
                    UpadteSharkSpeciesDetail,
                    (res) => {
                        setEditedData(values);
                        setRefresh((prev) => !prev);
                    },
                    forPayload,
                    "Shark Species Details Update Successfully"
                );

                setIsEditing(false);
            })
            .catch((info) => {
                console.log("Validate Failed:", info);
            });
    };

    const [form] = Form.useForm();

    useEffect(() => {

    }, []);


    const handleRepostionEdit = () => {
        setIsRepositionEdit(pr => !pr);
    }


    return (
        <>
            <Breadcrumb
                className="mx-2 mb-3 mt-6 "
                separator=" > "
                items={[
                    {
                        title: "Home",
                    },
                    {
                        href: `${appRoot}/${ROUTES.SHARK}/${ROUTES.CATEGORY}`,
                        title: "Shark Species",
                    },
                    {
                        title: intialSharkSpeciesData?.name,
                    },
                ]}
            />
            <Card bordered className="m-4">
                <Row justify="space-between" className=" flex-wrap">
                    <Typography.Title level={3} className="flex justify-between ">
                        Shark Species Details
                    </Typography.Title>
                    <div className="flex gap-3 flex-wrap">
                        <Button type="default" onClick={handleRepostionEdit}>
                            Reposition
                        </Button>
                        {isEditing ? (
                            <Button
                                type="primary"
                                danger
                                onClick={() => {
                                    // setEditedData({});
                                    setIsEditing(false);
                                }}
                            >
                                Cancel
                            </Button>
                        ) : (
                            <Button danger onClick={handleEdit}>
                                Edit
                            </Button>
                        )}
                        <AddLocationImage
                            locationData={intialSharkSpeciesData}
                            refresher={() => {
                                setRefresh((prev) => !prev);
                            }}
                            API={API}
                            type="sharkSpeciesId"
                        />
                    </div>
                </Row>

                {intialSharkSpeciesData?.imageId && (
                    <Row className=" items-center justify-center">
                        <Image
                            width={230}
                            src={FinalImageGenerator(intialSharkSpeciesData?.image, 400)}
                            preview={{
                                src: FinalImageGenerator(intialSharkSpeciesData?.image, 1600, 3),
                            }}
                        />
                    </Row>
                )}
                <Modal
                    open={isRepositionEdit}
                    title="Image Reposition"
                    width={"100%"}
                    onCancel={handleRepostionEdit}
                    loading={API?.isLoading}
                    onOk={() => {

                        const UpadteSharkSpeciesDetail = apiGenerator(CONSTANTS.API.BeachPhoto.update, {
                            id: intialSharkSpeciesData?.image?.id,
                        });
                        if (percentageY != intialSharkSpeciesData?.image?.position?.objectPositionY)
                            API.sendRequest(
                                UpadteSharkSpeciesDetail,
                                (res) => {
                                    setRefresh((prev) => !prev);
                                    handleRepostionEdit();
                                },
                                {
                                    position: {
                                        objectPositionY: percentageY
                                    }
                                },
                                "Reposition Update Successfully"
                            );
                    }}
                >
                    <DraggableImage
                        imageUrl={intialSharkSpeciesData?.image?.imageUrl}
                        defaultPosition={intialSharkSpeciesData?.image?.position}
                        percentageY={percentageY}
                        setPercentageY={setPercentageY}
                        imgHeight={"md:h-[520px] h-[260px]"}
                    // onPositionChange={(pos) => { console.log("first", pos) }}
                    />
                </Modal>
                <FormFields
                    // menuFields={CONSTANTS.FORM_FIELD.EDIT_COUNTRY_STATE_CITY.filter(
                    //   (el) =>
                    //     !el?.fieldFilter ||
                    //     !Array.isArray(el?.fieldFilter) ||
                    //     el?.fieldFilter?.findIndex((fil) => fil === "Country") !== -1
                    // )}
                    menuFields={[
                        ...CONSTANTS.FORM_FIELD.EDIT_SHARKSPECIES_DETAIL,
                        ...(isEditing
                            ? [
                                {
                                    no: 7,
                                    Label: "description",
                                    name: "description",
                                    id: "description",
                                    type: "SimpleRichTextArea",
                                    placeholder: "Enter description",
                                },
                            ]
                            : []),
                    ]}
                    formData={editedData}
                    form={form}
                    disabled={!isEditing}
                />
                {!isEditing ? (
                    <Card bordered className="my-2 border-gray-400" key={"description"}>
                        <Typography.Title level={4} className="!pb-1 !mb-1 capitalize">
                            Description
                        </Typography.Title>
                        <Typography.Title
                            level={5}
                            className="!m-0 !p-0 capitalize"
                        // dangerouslySetInnerHTML={{ __html: el?.value }}
                        >
                            {/* {`${el?.label} : `} */}
                            <EditorContent
                                className="font-light"
                                value={editedData?.description}
                            />
                            {/* <span
                                className="font-light"
                                dangerouslySetInnerHTML={{
                                    __html: editedData?.description,
                                    // __html: editedData?.[el?.label],
                                }}
                            ></span> */}
                        </Typography.Title>
                    </Card>
                ) : null}
                {isEditing && (
                    <>
                        <Button type="primary" onClick={handleFormSubmit}>
                            Save
                        </Button>
                    </>
                )}
            </Card>
        </>
    )
}

export default SharkSpeciesDetail