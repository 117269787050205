import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useHttp from "../../../../../hooks/use-http";
import {
  EditorContent,
  FinalImageGenerator,
  ProceedImageURL,
  apiGenerator,
} from "../../../../../util/functions";
import CONSTANTS from "../../../../../util/constant/CONSTANTS";
import { payloadGenerator } from "../../../../../component/common/CRUD-Component";
import { Breadcrumb, Button, Card, Form, Image, Modal, Row, Typography } from "antd";
import FormFields from "../../../../../component/common/FormFields";
import { ROUTES, appRoot } from "../../../../../util/Route";
import AddLocationImage from "../../../../../component/common/Location/Add-Location-Image";
import DraggableImage from "../../../../../component/common/drag-image";

const StateLocation = () => {
  const [isEditing, setIsEditing] = useState(false);
  const [isRepositionEdit, setIsRepositionEdit] = useState(false);
  const [IntialStateData, setInitialStateData] = useState({});
  const [editedData, setEditedData] = useState({});
  const [refresh, setRefresh] = useState(true);
  const [percentageY, setPercentageY] = useState(
    0); // Extract initial percentage
  const param = useParams();
  const navigate = useNavigate();
  const API = useHttp();
  const handleEdit = () => {
    setIsEditing(true);
  };
  useEffect(() => {
    const BasicDetailsAPI = apiGenerator(
      CONSTANTS.API.State.getOne,
      {},
      `${param?.stateSlug}`
    );
    API.sendRequest(BasicDetailsAPI, (res) => {
      if (!res?.data) {
        navigate(`${appRoot}/${ROUTES.LOCATION}`);
      }
      setEditedData({
        ...res?.data,
        tagIds: res?.data?.locationTags?.map((el) => el?.tag?.id),
        lat: res?.data?.GeoLoc?.coordinates[1],
        lng: res?.data?.GeoLoc?.coordinates[0],
      });
      res?.data?.image?.position?.objectPositionY && setPercentageY(res?.data?.image?.position?.objectPositionY ?? 0)
      setInitialStateData(res?.data);
    });
  }, [refresh]);
  useEffect(() => {
    const AddLocationTagField =
      CONSTANTS.FORM_FIELD.ADD_COUNTRY_STATE_CITY.find(
        (fil) => fil.id === "tagIds"
      );
    const EditLocationTagField =
      CONSTANTS.FORM_FIELD.EDIT_COUNTRY_STATE_CITY.find(
        (fil) => fil.id === "tagIds"
      );

    if (AddLocationTagField?.filters?.length) {
      return;
    }
    API.sendRequest(
      CONSTANTS.API.Tag.getAll,
      (res) => {
        const OptionTags = res?.data?.rows?.map((tag) => ({
          id: tag?.id,
          label: tag?.tag,
          value: tag?.id,
        }));
        // console.log(options);
        AddLocationTagField.option = OptionTags;
        EditLocationTagField.option = OptionTags;
      },
      {
        limit: 1000,
      }
    );
  }, []);
  const handleFormSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        // let payload = getClearObject(values);

        const forPayload = payloadGenerator(
          values,
          [...CONSTANTS.FORM_FIELD.EDIT_COUNTRY_STATE_CITY.filter(
            (el) =>
              !el?.fieldFilter ||
              !Array.isArray(el?.fieldFilter) ||
              el?.fieldFilter?.findIndex((fil) => fil === "State") !== -1
          ), ...(isEditing
            ? [
              {
                no: 7,
                Label: "description",
                name: "summary",
                id: "summary",
                type: "SimpleRichTextArea",
                placeholder: "Enter description",
              },
            ]
            : []),],
          true
        );
        if (values?.countryId) {
          forPayload.append("countryId", values?.countryId);
        }
        // if (
        //   IntialStateData &&
        //   IntialStateData?.rank &&
        //   IntialStateData?.rank === values?.rank
        // ) {
        //   // if(IntialCityData?.rank && values?.rank === null) return
        //   forPayload.delete("rank");
        // }
        const UpadteCountryDetail = apiGenerator(CONSTANTS.API.State.update, {
          id: IntialStateData?.id,
        });
        //  payload.AllBeachId = beachData?.id;
        API.sendRequest(
          UpadteCountryDetail,
          (res) => {
            setEditedData(values);
            setRefresh((prev) => !prev);
          },
          forPayload,
          "State Details Updated successfully"
        );

        setIsEditing(false);
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  const [form] = Form.useForm();
  const handleRepostionEdit = () => {
    setIsRepositionEdit(pr => !pr);
  }
  return (
    <>
      <Breadcrumb
        className="mx-2 mb-3 mt-6 "
        separator=" > "
        items={[
          {
            title: "Home",
          },
          {
            href: `${appRoot}/${ROUTES.LOCATION}`,
            title: "Location",
          },
          {
            title: "State",
          },
          {
            title: IntialStateData?.name,
          },
        ]}
      />
      <Card bordered className="m-4">
        <Row justify="space-between" className=" flex-wrap">
          <Typography.Title level={3} className="flex justify-between ">
            State Details
          </Typography.Title>
          <div className="flex gap-3 flex-wrap">
            <Button type="default" onClick={handleRepostionEdit}>
              Reposition
            </Button>
            {isEditing ? (
              <Button
                type="primary"
                danger
                onClick={() => {
                  // setEditedData({});
                  setIsEditing(false);
                }}
              >
                Cancel
              </Button>
            ) : (
              <Button danger onClick={handleEdit}>
                Edit
              </Button>
            )}
            <AddLocationImage
              locationData={IntialStateData}
              refresher={() => {
                setRefresh((prev) => !prev);
              }}
              API={API}
              type="State"
            />
          </div>
        </Row>

        {IntialStateData?.imageId && (
          <Row className=" items-center justify-center">
            <Image
              width={230}
              src={FinalImageGenerator(IntialStateData?.image, 400)}
              preview={{
                src: FinalImageGenerator(IntialStateData?.image, 1600, 3),
              }}
            />
          </Row>
        )}

        <Modal
          open={isRepositionEdit}
          title="Image Reposition"
          width={"100%"}
          onCancel={handleRepostionEdit}
          loading={API?.isLoading}
          onOk={() => {

            const UpadteCountryDetail = apiGenerator(CONSTANTS.API.BeachPhoto.update, {
              id: IntialStateData?.image?.id,
            });
            if (percentageY != IntialStateData?.image?.position?.objectPositionY)
              API.sendRequest(
                UpadteCountryDetail,
                (res) => {
                  setRefresh((prev) => !prev);
                  handleRepostionEdit();
                },
                {
                  position: {
                    objectPositionY: percentageY
                  }
                },
                "Reposition Update Successfully"
              );
          }}
        >
          <DraggableImage
            imageUrl={IntialStateData?.image?.imageUrl}
            defaultPosition={IntialStateData?.image?.position}
            percentageY={percentageY}
            setPercentageY={setPercentageY}
          // onPositionChange={(pos) => { console.log("first", pos) }}
          />
        </Modal>
        <FormFields
          menuFields={[
            ...CONSTANTS.FORM_FIELD.EDIT_COUNTRY_STATE_CITY.filter(
              (el) =>
                !el?.fieldFilter ||
                !Array.isArray(el?.fieldFilter) ||
                el?.fieldFilter?.findIndex((fil) => fil === "State") !== -1
            ),
            ...(isEditing
              ? [
                {
                  no: 7,
                  Label: "description",
                  name: "summary",
                  id: "summary",
                  type: "SimpleRichTextArea",
                  placeholder: "Enter description",
                },
              ]
              : []),
          ]}
          formData={editedData}
          form={form}
          disabled={!isEditing}
        />
        {!isEditing && editedData?.summary && editedData?.summary !== '<p><br></p>' ? (
          <Card bordered className="my-2 border-gray-400" key={"description"}>
            <Typography.Title level={4} className="!pb-1 !mb-1 capitalize">
              Description
            </Typography.Title>
            <Typography.Title
              level={5}
              className="!m-0 !p-0 capitalize"
            // dangerouslySetInnerHTML={{ __html: el?.value }}
            >
              {/* {`${el?.label} : `} */}
              <EditorContent
                className="font-light"
                value={editedData?.summary}
              />
              {/* <span
                className="font-light"
                dangerouslySetInnerHTML={{
                  __html: editedData?.summary,
                  // __html: editedData?.[el?.label],
                }}
              ></span> */}
            </Typography.Title>
          </Card>
        ) : null}
        {isEditing && (
          <>
            <Button type="primary" onClick={handleFormSubmit}>
              Save
            </Button>
          </>
        )}
      </Card>
    </>
  );
};

export default StateLocation;
